import Footer from "../../components/footer";
import { ReactComponent as Phone } from "../../assets/svg/phone.svg";
import { ReactComponent as Email } from "../../assets/svg/email.svg";
import { useState } from "react";
import emailjs from "emailjs-com";
function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_c104r1q", // Service ID
        "template_sovwken", // Template ID
        e.target, // Form element
        "_VJKcxc3QzsUtg9NF" // Public key (User ID)
      )
      .then(
        (result) => {
          console.log("Email successfully sent:", result.text);
          alert("Message Sent, We will get back to you shortly.");
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          alert("An error occurred. Please try again.");
        }
      );
  };

  return (
    <div data-aos="fade" className="aos-init aos-animate">
      <div className="container mb-8 mx-auto px-4 sm:px-5 md:px-10 lg:px-[60px]">
        <div className="py-12">
          <div className="flex items-center mb-6">
            <h1 className="text-4xl font-semibold after-effect after:left-52 dark:text-white">
              Contact
            </h1>
            <div className="h-1 w-24 bg-red-500  ml-5 mt-2"></div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
          <div className="bg-blue-50 p-5 rounded-lg flex dark:bg-[#1D1D1D]">
            <div className="flex items-center">
              <span className="flex-shrink-0 mr-5 bg-white dark:bg-black text-[#E93B81] shadow-md p-3 rounded-md">
                <Phone />
              </span>

              <div className="dark:text-white">
                Phone :
                <p className="text-gray-600 dark:text-[#A6A6A6]">
                  +964 750 837 0388
                </p>
              </div>
            </div>
          </div>
          <div className="bg-blue-50 p-5 rounded-lg flex  items-center dark:bg-[#1D1D1D]">
            <span className="flex-shrink-0 bg-white dark:bg-black text-[#FD7590] shadow-md h-1/2 mr-5 p-3 rounded-md">
              <Email />
            </span>
            <div>
              <h2 className="font-semibold mb-2 flex items-center dark:text-white">
                Email :
              </h2>
              <p className="text-gray-600 dark:text-[#A6A6A6]">
                info@sorantaha.com
              </p>
              <p className="text-gray-600 dark:text-[#A6A6A6]">
                soran.taha.aziz@gmail.com
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 p-6 rounded-lg dark:bg-[#1D1D1D]">
          <p className="text-xl text-gray-700 mb-2 dark:text-[#A6A6A6]">
            I'm always open to discussing product
          </p>
          <p className="text-2xl font-bold mb-6 dark:text-white">
            design work or partnerships.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-8">
              <label className="block text-sm font-medium dark:text-[#A6A6A6]">
                Name *
              </label>
              <input
                value={formData.name}
                onChange={handleChange}
                type="text"
                id="name"
                name="name"
                required
                className="mt-1 block w-full border-b dark:text-[#A6A6A6] border-gray-300 bg-transparent"
              />
            </div>
            <div className="mb-8">
              <label className="block text-sm font-medium dark:text-[#A6A6A6]">
                Email *
              </label>
              <input
                value={formData.email}
                onChange={handleChange}
                type="email"
                id="email"
                name="email"
                required
                className="mt-1 block w-full border-b dark:text-[#A6A6A6] border-gray-300 bg-transparent"
              />
            </div>
            <div className="mb-8">
              <label className="block text-sm font-medium dark:text-[#A6A6A6] ">
                Message *
              </label>
              <input
                value={formData.message}
                onChange={handleChange}
                type="text"
                id="message"
                name="message"
                required
                className="mt-1 block w-full border-b dark:text-[#A6A6A6] border-gray-300 bg-transparent"
              />
            </div>
            <button
              type="submit"
              className="px-6 py-2 border dark:text-[#A6A6A6] hover:text-white hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476] border-gray-300 rounded-full text-sm font-medium text-gray-700 hover:bg-gray-100 dark:hover:border-[#00000000] transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
