const data = {
  educations: [
    {
      date: "2013 - 2017",
      title: "Bachelor in Software Engineering",
      description:
        "specializing in programming, system design, and development, providing a strong foundation for a career in tech.",
      place: "Koya University",
    },
  ],
  experiences: [
    {
      date: "Jan 2020 - Present",
      position: "Freelancer",
      description:
        "I have successfully managed web development projects and provided hosting, domain, and email services to clients. My tailored solutions meet diverse client needs and reflect a commitment to quality and client satisfaction.",
      place: "Online",
    },
    {
      date: "Jun 2021 - Present",
      position: "Web Developer",
      description:
        "I played a vital role in managing, Creating and enhancing the company's online presence, including creating its website, hosting, domains, and email systems, ensuring a seamless and informative online experience for clients and stakeholders.",
      place: "Alhadbaa Company",
    },
    {
      date: "Mar 2019 - Present",
      position: "Graphic Designer",
      description:
        "As a Graphic Designer, I played a key role in creating visually compelling marketing materials, ensuring a cohesive and engaging brand image for the company.",
      place: "Alhadbaa Company",
    },
    {
      date: "Sep 2021 - Present",
      position: "Senior Accountant",
      description:
        "I held a pivotal role overseeing all financial operations, including inventory management, storage, distribution, and client offers. Additionally, I managed relationships with agents, distributors, and ensured the efficient operation of storages and warehouses, contributing to the company's financial stability and growth.",
      place: "Alhadbaa Company",
    },
    {
      date: "Apr 2020 - Sep 2021",
      position: "Accountant",
      description:
        "Manage financial records, oversee budgeting and reporting, ensure accurate inventory, and coordinate with distributors and agents for smooth operations.",
      place: "Alhadbaa Company",
    },
    {
      date: "Oct 2018 - Present",
      position: "Sales Representative",
      description:
        "I proactively identified and pursued sales opportunities, consistently surpassing targets. I provided personalized product knowledge and exceptional customer service, fostering strong client relationships. My achievements contributed to revenue growth and overall client satisfaction.",
      place: "Alhadbaa Company",
    },
  ],
  skills: [
    { title: "Kurdish", percentage: "w-[100%]", bgColor: "bg-[#ef4444]" },
    { title: "arabic", percentage: "w-[85%]", bgColor: "bg-[#ef4444]" },
    { title: "english", percentage: "w-[75%]", bgColor: "bg-[#ef4444]" },
  ],
  softSkills: [
    "Communication",
    "Problem-solving",
    "Time management",
    "Adaptability",
    "Teamwork and collaboration",
    "Creativity",
    // "Attention to detail",
    "Critical thinking",
    "Multitasking",
    "Decision-making",
    "Organization",
    "Client relationship management",
  ],
  technicalSkills: [
    "PHP",
    "Javascript",
    "CSS",
    "HTML",
    "Laravel(PHP)",
    "Livewire",
    "React JS",
    "Vue JS",
    "alpine JS",
    "Shadcn",
    "Daisy UI",
    "Headless UI",
    "Tailwind",
    "Bootstrap",
    "Semantic UI",
    "Meterialize",
  ],
  programs: [
    "Adobe PhotoShop",
    "Adobe Premiere",
    "Adobe AfterEffect",
    "Adobe illustrator",
    "Microsoft Excel",
    "Microsoft Access",
    "Microsoft Word",
    "Microsoft PowerPoint",
  ],
};
export default data;
