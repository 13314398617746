import Footer from "../../components/footer";
import data from "./data";
import { ReactComponent as Book } from "../../assets/svg/book.svg";
import { ReactComponent as Case } from "../../assets/svg/case.svg";

function Resume() {
  return (
    <>
      <div data-aos="fade" className="aos-init aos-animate">
        <div className="container px-4 mx-auto sm:px-5 md:px-10 lg:px-14">
          <div className="py-12">
            <div className="flex items-center mb-6">
              <h1 className="text-4xl font-semibold after-effect after:left-52 dark:text-white">
                Resume
              </h1>
              <div className="h-1 w-24 bg-red-500  ml-5 mt-2"></div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-1  gap-8">
              <div className="my-10 md:my-0">
                <h2 className="text-2xl font-semibold mb-4 flex items-center dark:text-white">
                  <Book />
                  Education
                </h2>
                <div className="space-y-4">
                  {data.educations.map((educations, index) => (
                    <div
                      className="bg-pink-50 p-4 dark:bg-[#111111] dark:border-2 rounded-lg dark:border-[#1D1D1D]"
                      key={index}
                    >
                      <p className="text-sm text-gray-600 dark:text-[#A6A6A6]">
                        {educations.date}
                      </p>
                      <h3 className="font-semibold dark:text-white">
                        {educations.title}
                      </h3>
                      <p className="text-sm dark:text-[#A6A6A6]">
                        {educations.place}
                      </p>
                      <p className="text-gray-400 dark:text-[#525252] text-sm">
                        {educations.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h2 className="text-2xl font-semibold mb-4 flex items-center dark:text-white">
                  <Case />
                  Experience
                </h2>
                <div className="space-y-4">
                  {data.experiences.map((experiences, index) => (
                    <div className="flex justify-between">
                      <div
                        className={`${
                          index % 2 === 0 ? "bg-blue-50" : "bg-yellow-50 "
                        } p-4 rounded-lg dark:bg-[#111111] dark:border-2 dark:border-[#1D1D1D]`}
                        key={index}
                      >
                        <p className="text-sm text-gray-600 dark:text-[#A6A6A6]">
                          {experiences.date}
                        </p>
                        <h3 className="font-semibold dark:text-white ">
                          {experiences.position}
                        </h3>
                        <p className="text-sm dark:text-[#A6A6A6]">
                          {experiences.place}
                        </p>
                        <p className="text-gray-400 dark:text-[#525252] text-sm h-01 overflow-hidden">
                          {experiences.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container bg-[#f8fbfb] dark:bg-[#0D0D0D] py-12 px-4 sm:px-5 md:px-10 lg:px-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-2xl font-semibold mb-5 dark:text-white">
                Language
              </h2>
              <div className="space-y-4 ">
                {data.skills.map((skills, index) => (
                  <div key={index}>
                    <div className="flex justify-between mb-1">
                      <span className="dark:text-[#A6A6A6] text-lg font-semibold">
                        {skills.title}
                      </span>
                      <span className="dark:text-[#A6A6A6] text-lg font-semibold">
                        {skills.percentage.split("[")[1].split("]")[0]}
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-1">
                      <div
                        className={`${skills.bgColor} h-1 rounded-full ${skills.percentage}`}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-4 dark:text-white">
                Programs
              </h2>
              <div className="flex flex-wrap gap-2">
                {data.programs.map((index) => (
                  <span
                    key={index}
                    className="bg-gray-200 rounded-full px-3 py-1 text-sm dark:bg-[#1D1D1D] dark:text-[#A6A6A6]"
                  >
                    {index}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-4 dark:text-white">
                Soft Skills
              </h2>
              <div className="flex flex-wrap gap-2">
                {data.softSkills.map((value, index) => (
                  <span
                    key={index}
                    className="border-2 border-gray-200 rounded-md px-3 py-1 text-sm dark:border-[#1D1D1D] dark:text-[#A6A6A6]"
                  >
                    {value}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-4 dark:text-white">
                Technical Skills
              </h2>
              <div className="flex flex-wrap gap-2">
                {data.technicalSkills.map((index) => (
                  <span
                    key={index}
                    className="border-2 border-gray-200 rounded-md px-3 py-1 text-sm dark:border-[#1D1D1D] dark:text-[#A6A6A6]"
                  >
                    {index}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Resume;
