import { Link } from "react-router-dom";
import { ReactComponent as Sun } from "../assets/svg/sun.svg";
import { ReactComponent as MoonOutline } from "../assets/svg/moonOutline.svg";
import { ReactComponent as Hamburger } from "../assets/svg/hamburger.svg";
import { ReactComponent as Cross } from "../assets/svg/cross.svg";
import { ReactComponent as Logo } from "../assets/svg/stlogo.svg";
import { useState } from "react";
import NavMobile from "./nav/navMobile";

function Header() {
  const [openNave, setOpenNav] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  return (
    <>
      <div className="fixed z-20 lg:relative xl:max-w-[1280px] lg:max-w-[992px] grid-cols-12 md:gap-10 mx-auto w-full bg-[#F3F6F6] dark:bg-black lg:bg-transparent lg:dark:bg-transparent flex justify-between py-5 lg:px-0 lg:pt-[50px]">
        <div className="w-full flex justify-between px-4">
          <div className="w-full flex justify-between px-4 items-center">
            <Link to="/">
              <Logo className="text-3xl h-6 lg:h-8 dark:text-white dark:hover:text-red-500 text-red-500 font-bold transition-all duration-300 ease-in-out dark:border-white" />
            </Link>
            <div className="flex items-center">
              <span
                onClick={() => {
                  setDarkMode(!darkMode);
                  !darkMode
                    ? document
                        .getElementsByTagName("html")
                        .item("html")
                        .classList.add("dark")
                    : document
                        .getElementsByTagName("html")
                        .item("html")
                        .classList.remove("dark");
                }}
                className="flex dark:text-black dark:bg-white bg-white  w-[40px] hover:text-white h-[40px] rounded-full lg:flex justify-center items-center text-black hover:bg-[#ef4060] transition-all duration-300 ease-in-out cursor-pointer ml-2 "
              >
                {!darkMode ? <MoonOutline /> : <Sun />}
              </span>

              <span
                onClick={() => setOpenNav(!openNave)}
                className="lg:opacity-0 lg:hidden opacity-100 bg-[#ef4060] w-[40px] h-[40px] rounded-full flex justify-center cursor-pointer items-center text-white dark:text-white text-3xl ml-3 "
              >
                {!openNave ? <Hamburger /> : <Cross />}
              </span>
            </div>
          </div>
        </div>
      </div>
      <NavMobile
        className=""
        state={openNave}
        openNavStateChanger={setOpenNav}
      />
    </>
  );
}
export default Header;
